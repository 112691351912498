import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { format } from "date-fns";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EyeIcon from "@material-ui/icons/RemoveRedEye";

const Example = ({
  data: initialData,
  setOpenEdit,
  setOpenView,
  setOpenRemove,
  setCategoryId,
}) => {
  const transformData = (inputData) => {
    // Tüm veriyi bir harita haline getiriyoruz
    const idMap = new Map();
    inputData.forEach((item) => {
      idMap.set(item.id, {
        ...item,
        created: item.created
          ? format(new Date(item.created), "yyyy-MM-dd")
          : null, // Tarihi formatla
        subRows: [],
      });
    });

    // Kök seviyeyi organize etmek için bir dizi oluşturuyoruz
    const transformedData = [];

    inputData.forEach((item) => {
      const current = idMap.get(item.id);
      if (item.parentCategoryId) {
        // Eğer parentCategoryId varsa, ilgili parent'ın subRows'una eklenir
        const parent = idMap.get(item.parentCategoryId);
        if (parent) {
          parent.subRows.push(current);
        }
      } else {
        // ParentCategoryId yoksa, kök seviyeye eklenir
        transformedData.push(current);
      }
    });

    return transformedData;
  };

  const data = transformData(initialData);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Ad",
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        accessorKey: "created",
        header: "Yaradılma tarixi",
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        accessorKey: "actions",
        header: "Əməliyyatlar",
        enableSorting: false,
        enableColumnActions: false,

        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px" }}>
            <Tooltip title="Hamısını göstər" placement="top">
              <button
                aria-label="view"
                className="btn btn-sm btn-icon btn-bg-light btn-text-info btn-hover-info mr-2"
                onClick={(e) => {
                  setOpenView(e);
                  setCategoryId(row?.original?.id);
                }}
              >
                <EyeIcon />
              </button>
            </Tooltip>

            <Tooltip title="Düzəliş et" placement="top">
              <button
                aria-label="edit"
                className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                onClick={() => {
                  setOpenEdit(true);
                  setCategoryId(row?.original?.id);
                }}
              >
                <EditIcon />
              </button>
            </Tooltip>

            <Tooltip title="Sil" placement="top">
              <button
                aria-label="Delete"
                className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                onClick={() => {
                  setOpenRemove(true);
                  setCategoryId(row?.original?.id);
                }}
              >
                <DeleteIcon />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false,
    enableExpanding: true,
    filterFromLeafRows: true,
    getSubRows: (row) => row.subRows,
    initialState: { expanded: false },
    paginateExpandedRows: false,
  });

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <MaterialReactTable
        table={table}
        muiTableContainerProps={{
          style: {
            width: "100%",
          },
        }}
        muiTableProps={{
          style: {
            tableLayout: "auto",
            width: "100%",
          },
        }}
        muiColumnVirtualizerProps={{
          style: {
            flexGrow: 1,
          },
        }}
      />
    </div>
  );
};

export default Example;

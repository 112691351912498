import React, { useState } from "react";
import DataTables from "./components/ScrapeTables";
import { useLocation, useParams, useHistory } from "react-router-dom";
import View from "./modals/View";
import Create from "./modals/Create";
import Delete from "./modals/Remove";
import Filter from "./modals/Filter";
import Edit from "./modals/Edit";
import Switch from "@material-ui/core/Switch/index";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import { updateObject } from "../../../Helpers/updateObject";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import {
  useGetScrapeTemplatesQuery,
  useChangeStatusMutation,
} from "../../../redux/api/ScrapeTemplates/templatesApi";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import RenderIf from "../UI/RenderIf";

export function ScrapeTemplates() {
  let location = useLocation();
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = React.useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const NewsSourceScrapeTemplateId = searchParams.get(
    "NewsSourceScrapeTemplateId"
  );
  const { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const permissions = user?.permissions;
  let history = useHistory();

  const handleChange = () => {
    setShowFilter(!showFilter);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowView(open);
  };

  function doc_keyUp(e) {
    if (e.ctrlKey && e.key === "x") {
      setShowCreate(true);
    }
  }
  document.addEventListener("keyup", doc_keyUp, false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [templateId, setTemplateId] = useState(null);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);
  const [filterInputs, setFilterInputs] = useState({
    SearchTerm: "",
    name: "",
    Url: "",
    address: "",
    email: "",
    deleted: false,
    FromDate: "",
    ToDate: "",
    NewsSourceId: id,
    Id: NewsSourceScrapeTemplateId,
  });

  const { data, isLoading, refetch } = useGetScrapeTemplatesQuery({
    skip: page * rowsPerPage,
    take: rowsPerPage,
    field: "id",
    orderBy: false,
    name: filterInputs,
  });

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const filterInputsUpdate = (e) => {
    setPage(0);

    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });
    setFilterInputs(updateFiltersInput);
  };
  const filterStatusesUpdate = (key, value) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });

    setFilterInputs(updateFiltersInput);
  };
  const [changeStatus, { isSuccess }] = useChangeStatusMutation();

  const onUpdateUserStatus = (id, statusType) => {
    let status = !statusType;
    const asset = {
      id,
      status,
    };

    changeStatus(asset);
  };
  return (
    <div className="row bg-white rounded d-flex  justify-content-center h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <button onClick={() => history.goBack()} className="handleGoBack">
          {" "}
          <KeyboardBackspaceIcon /> Geri
        </button>
        <h1 className="display-4 mb-0 m-4">
        {Object.values(location?.state).join('')} -Scrape Şablonları
        </h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label={showFilter ? "Filtri gizlət" : "Filtri göstər"}
          className="ml-auto mb-0"
        />
      </div>
      <RenderIf condition={isLoading}>
        <div>
          <CircularProgress />
        </div>
      </RenderIf>
      <RenderIf condition={data && !isLoading}>
        <DataTables
          setOpenEdit={setShowEdit}
          setOpenView={toggleDrawer(true)}
          setOpenRemove={setShowRemove}
          setOpenCreate={setShowCreate}
          rows={data?.data}
          count={data?.count}
          rowsPerPage={rowsPerPage}
          page={page}
          id={id}
          name={location?.state}
          permissions={permissions}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          setTemplateId={setTemplateId}
          setDeleteUsersID={setDeleteUsersIds}
          deleteUsersIds={deleteUsersIds}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterStatusesUpdate={filterStatusesUpdate}
          onUpdateUserStatus={onUpdateUserStatus}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          refetch={refetch}
        />
      </RenderIf>

      <RenderIf condition={showView}>
        <View open={showView} onClose={toggleDrawer(false)} id={templateId} />
      </RenderIf>
      <RenderIf condition={showCreate}>
        <Create
          id={id}
          show={showCreate}
          onHide={setShowCreate}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showEdit}>
        <Edit
          sourceId={id}
          id={templateId}
          show={showEdit}
          onHide={setShowEdit}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showRemove}>
        <Delete
          id={templateId}
          show={showRemove}
          onHide={setShowRemove}
          filterInputs={filterInputs}
        />
      </RenderIf>

      <Filter
        setPage={setPage}
        show={showFilter}
        onHide={handleChange}
        filterInputsUpdate={filterInputsUpdate}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
      />
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
